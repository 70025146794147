import React, { useState } from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  Image,
  Input,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Divider,
  useToast,
  Link,
  Flex,
  Stack,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { UserAuth } from "../contexts/AuthContext";
import GoogleSignInButton from "../components/GoogleSignInButton";
import logo from "../assets/logos/logo.png";
import PasswordResetModal from "../components/PasswordResetModal";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const toast = useToast();
  const { signInWithEmail } = UserAuth();
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    if (!email) newErrors.email = "Email is required";
    if (!password) newErrors.password = "Password is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      await signInWithEmail(email, password);
    } catch (error) {
      toast({
        title: "Sign in failed",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      width="100%"
      minHeight="100vh"
      bg="primaryBackground"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      px={4}
      py={8}
    >
      <Stack align={"center"} textAlign={"center"} mb={8}>
        <Box as="button" cursor="pointer">
          <Image src={logo} alt="Logo" width={["200px", "250px"]} mb={8} />
        </Box>
        <Heading fontSize="xl" color="heading" textAlign="center">
          Sign in to your account
        </Heading>
        <Text textAlign="center" color="text" fontSize="sm">
          Or{" "}
          <Link
            as={RouterLink}
            to="/register"
            color="text"
            textDecoration={"underline"}
            _hover={{ textDecoration: "none" }}
          >
            create an account
          </Link>
        </Text>
      </Stack>
      <Box
        maxWidth={["100%", "450px", "500px"]}
        width="100%"
        bg="secondaryBackground"
        p={[6, 8, 10]}
        borderRadius="xl"
        boxShadow="md"
      >
        <VStack align="stretch">
          <GoogleSignInButton text="Sign in with Google" />

          <Flex align="center" pb={8}>
            <Divider borderColor="gray.300" />
            <Text px={2} color="gray.500" fontWeight="medium">
              or
            </Text>
            <Divider borderColor="gray.300" />
          </Flex>

          <form onSubmit={handleSubmit}>
            <VStack spacing={4}>
              <FormControl isInvalid={errors.email}>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.password}>
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <FormErrorMessage>{errors.password}</FormErrorMessage>
              </FormControl>

              <Link
                alignSelf="flex-start"
                color="text"
                fontSize="sm"
                fontWeight="bold"
                onClick={() => setIsResetModalOpen(true)}
                cursor="pointer"
                mb={2}
              >
                Forgot password?
              </Link>

              <Button
                width="100%"
                variant={"secondary"}
                type="submit"
                isLoading={isLoading}
              >
                Sign In
              </Button>
            </VStack>
          </form>
        </VStack>
      </Box>
      <PasswordResetModal
        isOpen={isResetModalOpen}
        onClose={() => setIsResetModalOpen(false)}
        initialEmail={email}
      />
    </Box>
  );
};

export default SignIn;
