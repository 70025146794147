import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Heading,
  Text,
  useToast,
  HStack,
  Select,
  SimpleGrid,
  Card,
  CardBody,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { apiUrl } from "../config";
import Navbar from "../components/Navbar";
import { UserAuth } from "../contexts/AuthContext";
import SubmissionSuccess from "../components/SubmissionSuccess";
import FormStepper from "../components/FormStepper";
import PhoneNumberInput from "../components/PhoneNumberInput";

const ORGANIZATION_CATEGORIES = [
  { value: "school", label: "School" },
  { value: "religious", label: "Religious Organization" },
  { value: "sports", label: "Sports Team/Club" },
  { value: "nonprofit", label: "Nonprofit Organization" },
  { value: "community", label: "Community Group" },
  { value: "youth", label: "Youth Organization" },
  { value: "arts", label: "Arts & Culture Organization" },
  { value: "health", label: "Health Organization" },
  { value: "education", label: "Educational Organization" },
  { value: "charity", label: "Charitable Organization" },
  { value: "other", label: "Other" },
];

const steps = [
  { title: "Category" },
  { title: "Details" },
  { title: "Contact" },
];

const validationSchemas = [
  // Step 1: Type Selection
  Yup.object().shape({
    isIndividual: Yup.boolean().required("Organization type is required"),
  }),
  // Step 2: Details
  Yup.object().shape({
    name: Yup.string().required("Name is required"),
    category: Yup.string().when("isIndividual", {
      is: false,
      then: () => Yup.string().required("Organization category is required"),
    }),
    otherCategory: Yup.string().when(["isIndividual", "category"], {
      is: (isIndividual, category) => !isIndividual && category === "other",
      then: () =>
        Yup.string().required("Please specify the organization category"),
    }),
    description: Yup.string()
      .min(10, "Description must be at least 10 characters")
      .max(500, "Description must not exceed 500 characters")
      .required("Description is required"),
    location: Yup.string().required("Location is required"),
  }),
  // Step 3: Contact Info
  Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    phoneNumber: Yup.string()
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
      .required("Phone number is required"),
  }),
];

const OrganizationForm = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { user } = UserAuth();

  const initialValues = {
    name: "",
    email: "",
    phoneNumber: "",
    isIndividual: false,
    category: "",
    otherCategory: "",
    description: "",
    location: "",
  };

  const [activeStep, setActiveStep] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isIndividualType, setIsIndividualType] = useState(false);

  const handleSubmit = async (values, actions) => {
    try {
      const idToken = sessionStorage.getItem("idToken");
      await axios.post(
        `${apiUrl}/addOrganization`,
        {
          ...values,
          userId: user.uid,
          userFirstName: user.firstName,
          userLastName: user.lastName,
          userEmail: user.email,
          category: values.isIndividual
            ? null
            : values.category === "other"
            ? values.otherCategory
            : values.category,
          location: values.location,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      actions.setSubmitting(false);
      setIsSubmitted(true);
    } catch (error) {
      console.error("Error creating organization: ", error);
      actions.setSubmitting(false);
      toast({
        title: "Error",
        description: "Failed to create organization. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleTypeSelection = (isIndividual, formikProps) => {
    formikProps.setFieldValue("isIndividual", isIndividual);
    setIsIndividualType(isIndividual);
    if (isIndividual) {
      formikProps.setFieldValue("name", `${user.firstName} ${user.lastName}`);
      formikProps.setFieldValue("email", user.email);
    } else {
      formikProps.setFieldValue("name", "");
      formikProps.setFieldValue("email", "");
    }
  };

  const renderStepContent = (step, formikProps) => {
    switch (step) {
      case 0:
        return (
          <VStack spacing={6} width="100%">
            <Text textAlign="center" fontSize="lg">
              Choose your organization type
            </Text>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6} width="100%">
              <Card
                as="button"
                onClick={() => handleTypeSelection(false, formikProps)}
                cursor="pointer"
                bg={!formikProps.values.isIndividual ? "#f8ffec" : "white"}
                borderColor={
                  !formikProps.values.isIndividual
                    ? "primaryHighlight"
                    : "gray.200"
                }
                borderWidth="2px"
              >
                <CardBody>
                  <VStack spacing={3}>
                    <Text fontSize="xl" fontWeight="bold">
                      Organization
                    </Text>
                    <Text color="gray.600">
                      You are fundraising as an organization.
                    </Text>
                  </VStack>
                </CardBody>
              </Card>
              <Card
                as="button"
                onClick={() => handleTypeSelection(true, formikProps)}
                cursor="pointer"
                bg={formikProps.values.isIndividual ? "#f8ffec" : "white"}
                borderColor={
                  formikProps.values.isIndividual
                    ? "primaryHighlight"
                    : "gray.200"
                }
                borderWidth="2px"
              >
                <CardBody>
                  <VStack spacing={3}>
                    <Text fontSize="xl" fontWeight="bold">
                      Individual
                    </Text>
                    <Text color="gray.600">
                      You are fundraising as an individual.
                    </Text>
                  </VStack>
                </CardBody>
              </Card>
            </SimpleGrid>
          </VStack>
        );

      case 1:
        return (
          <VStack spacing={6}>
            <Field name="name">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.name && form.touched.name}
                  isRequired
                >
                  <FormLabel htmlFor="name">
                    {formikProps.values.isIndividual
                      ? "Individual Name"
                      : "Organization Name"}
                  </FormLabel>
                  <Input
                    {...field}
                    id="name"
                    placeholder={`Enter ${
                      formikProps.values.isIndividual
                        ? "individual"
                        : "organization"
                    } name`}
                  />
                  <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            {!formikProps.values.isIndividual && (
              <Field name="category">
                {({ field, form }) => (
                  <FormControl
                    isRequired={!formikProps.values.isIndividual}
                    isInvalid={form.errors.category && form.touched.category}
                    display={formikProps.values.isIndividual ? "none" : "block"}
                  >
                    <FormLabel>Organization Category</FormLabel>
                    <Select
                      {...field}
                      placeholder="Select organization category"
                      isDisabled={formikProps.values.isIndividual}
                    >
                      {ORGANIZATION_CATEGORIES.map((category) => (
                        <option key={category.value} value={category.value}>
                          {category.label}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{form.errors.category}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            )}

            {!formikProps.values.isIndividual &&
              formikProps.values.category === "other" && (
                <Field name="otherCategory">
                  {({ field, form }) => (
                    <FormControl
                      isRequired={
                        !formikProps.values.isIndividual &&
                        formikProps.values.category === "other"
                      }
                      isInvalid={
                        form.errors.otherCategory && form.touched.otherCategory
                      }
                      display={
                        !formikProps.values.isIndividual &&
                        formikProps.values.category === "other"
                          ? "block"
                          : "none"
                      }
                    >
                      <FormLabel>Specify Category</FormLabel>
                      <Input
                        {...field}
                        placeholder="Please specify your organization category"
                      />
                      <FormErrorMessage>
                        {form.errors.otherCategory}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              )}

            <Field name="description">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.description && form.touched.description
                  }
                  isRequired
                >
                  <FormLabel htmlFor="description">
                    {formikProps.values.isIndividual
                      ? "Individual Description"
                      : "Organization Description"}
                  </FormLabel>
                  <Input
                    {...field}
                    id="description"
                    as="textarea"
                    placeholder={`Describe your ${
                      formikProps.values.isIndividual
                        ? "individual profile"
                        : "organization"
                    }`}
                    minHeight="120px"
                    p={2}
                    resize="vertical"
                  />
                  <FormErrorMessage>{form.errors.description}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="location">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.location && form.touched.location}
                  isRequired
                >
                  <FormLabel htmlFor="location">City</FormLabel>
                  <Input {...field} id="location" placeholder="Enter city" />
                  <FormErrorMessage>{form.errors.location}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </VStack>
        );

      case 2:
        return (
          <VStack spacing={6}>
            <Field name="email">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.email && form.touched.email}
                  isRequired
                >
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    {...field}
                    id="email"
                    type="email"
                    placeholder="Enter email address"
                  />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="phoneNumber">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.phoneNumber && form.touched.phoneNumber
                  }
                  isRequired
                >
                  <FormLabel htmlFor="phoneNumber">Phone Number</FormLabel>
                  <PhoneNumberInput field={field} id="phoneNumber" />
                  <FormErrorMessage>{form.errors.phoneNumber}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </VStack>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Navbar />
      <Box minH="calc(100vh - 60px)" bg="primaryBackground" pt="60px" px={4}>
        <VStack
          align="center"
          maxWidth="800px"
          margin="0 auto"
          pt={8}
          spacing={8}
        >
          {!isSubmitted && (
            <Heading as="h1" size="lg" textAlign="center" color="heading">
              Create a new{" "}
              {activeStep === 0
                ? "Organization"
                : isIndividualType
                ? "Individual"
                : "Organization"}
            </Heading>
          )}

          {isSubmitted ? (
            <SubmissionSuccess />
          ) : (
            <Box
              width="100%"
              bg="secondaryBackground"
              p={8}
              borderRadius="xl"
              boxShadow="md"
            >
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchemas[activeStep]}
                onSubmit={async (values, actions) => {
                  if (activeStep === steps.length - 1) {
                    await handleSubmit(values, actions);
                  } else {
                    setActiveStep(activeStep + 1);
                    actions.setTouched({});
                    actions.setSubmitting(false);
                  }
                }}
              >
                {(props) => (
                  <Form>
                    <VStack spacing={8} width="100%">
                      <FormStepper steps={steps} activeStep={activeStep} />

                      <Box width="100%" pt={6}>
                        {renderStepContent(activeStep, props)}
                      </Box>

                      <HStack justify="space-between" width="100%" pt={4}>
                        <Button
                          variant="ghost"
                          onClick={() =>
                            setActiveStep(Math.max(activeStep - 1, 0))
                          }
                          isDisabled={activeStep === 0}
                        >
                          Previous
                        </Button>
                        <Button
                          variant="primary"
                          type="submit"
                          isLoading={props.isSubmitting}
                        >
                          {activeStep === steps.length - 1
                            ? "Create Profile"
                            : "Next"}
                        </Button>
                      </HStack>
                    </VStack>
                  </Form>
                )}
              </Formik>
            </Box>
          )}
        </VStack>
      </Box>
    </>
  );
};

export default OrganizationForm;
