import React, { useState, useEffect } from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  Button,
  useToast,
  Image,
  Spinner,
} from "@chakra-ui/react";
import { UserAuth } from "../contexts/AuthContext";
import logo from "../assets/logos/logo.png";
import { auth } from "../firebase";
import { sendEmailVerification } from "firebase/auth";

const EmailVerification = () => {
  const [isVerifying, setIsVerifying] = useState(true);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const toast = useToast();
  const { logout } = UserAuth();

  useEffect(() => {
    const checkVerification = setInterval(async () => {
      if (auth.currentUser) {
        await auth.currentUser.reload();
        if (auth.currentUser.emailVerified) {
          window.location.reload();
        }
      }
    }, 3000);

    return () => clearInterval(checkVerification);
  }, []);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setResendDisabled(false);
    }
  }, [countdown]);

  const handleResendEmail = async () => {
    try {
      if (auth.currentUser) {
        await sendEmailVerification(auth.currentUser);
        setResendDisabled(true);
        setCountdown(60);
        toast({
          title: "Verification email sent",
          description: "Please check your inbox",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <Box
      width="100%"
      height="100vh"
      bg="primaryBackground"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Image src={logo} alt="Logo" width="250px" mb={8} />
      <Box
        maxWidth="400px"
        width="100%"
        bg="secondaryBackground"
        p={10}
        borderRadius="xl"
        boxShadow="md"
      >
        <VStack spacing={6}>
          <Heading as="h1" size="lg" textAlign="center" color="heading">
            Verify Your Email
          </Heading>
          <Text textAlign="center" color="gray.600">
            We've sent a verification email to{" "}
            <strong>{auth.currentUser?.email}</strong>. Please check your inbox
            and click the verification link.
          </Text>
          <Button
            width="100%"
            variant={"primary"}
            onClick={handleResendEmail}
            isDisabled={resendDisabled}
          >
            {resendDisabled
              ? `Resend email in ${countdown}s`
              : "Resend verification email"}
          </Button>
          <Button width="100%" variant="ghost" onClick={handleLogout}>
            Sign Out
          </Button>
        </VStack>
      </Box>
    </Box>
  );
};

export default EmailVerification;
