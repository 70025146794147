import React from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  Circle,
  Icon,
  Button,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { CheckIcon } from "@chakra-ui/icons";
import Navbar from "../components/Navbar";

const SubmissionSuccess = () => {
  const navigate = useNavigate();

  return (
    <>
      <Navbar external={true} />
      <Box minH="calc(100vh - 60px)" bg="primaryBackground" pt="60px">
        <VStack
          spacing={6}
          py={12}
          px={4}
          align="center"
          maxW="600px"
          mx="auto"
          animation="fadeIn 0.5s ease-in"
        >
          <Circle
            size="100px"
            bg="primaryHighlight"
            color="white"
            animation="scaleIn 0.3s ease-in-out"
          >
            <Icon as={CheckIcon} w={10} h={10} />
          </Circle>

          <Box textAlign="center" pt={4}>
            <Heading size="lg" mb={4} color="heading">
              Business Information Submitted Successfully
            </Heading>
            <Text fontSize="lg" color="gray.600" mb={8}>
              Thank you for submitting a coupon on Coupal. Your submission has
              been received and will be added to the campaign's coupon book. You
              can now close this window.
            </Text>
          </Box>
        </VStack>
      </Box>
    </>
  );
};

export default SubmissionSuccess;
