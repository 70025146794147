import React from "react";
import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  FormErrorMessage,
  Select,
  Stack,
  Button,
  Box,
  Image,
  HStack,
  Text,
} from "@chakra-ui/react";
import { Field, Form } from "formik";
import FormStepper from "./FormStepper";
import PhoneNumberInput from "./PhoneNumberInput";
import { businessCategories } from "../constants/businessCategories";

export const steps = [
  { title: "Business Info" },
  { title: "Contact" },
  { title: "Coupon" },
  { title: "Security" },
];

const BusinessFormContent = ({
  activeStep,
  setActiveStep,
  handleLogoChange,
  logoPreview,
  isEditMode,
  isSubmitting,
}) => {
  const renderStepContent = (step) => {
    switch (step) {
      case 0: // Business Info
        return (
          <VStack spacing={6} width="100%">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={6}
              width="100%"
            >
              <Field name="name.en">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.name?.en && form.touched.name?.en}
                    isRequired
                  >
                    <FormLabel>Business Name (English)</FormLabel>
                    <Input {...field} />
                    <FormErrorMessage>{form.errors.name?.en}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="name.fr">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.name?.fr && form.touched.name?.fr}
                  >
                    <FormLabel>Business Name (French)</FormLabel>
                    <Input {...field} />
                    <FormErrorMessage>{form.errors.name?.fr}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>

            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={6}
              width="100%"
            >
              <Field name="description.en">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.description?.en &&
                      form.touched.description?.en
                    }
                    isRequired
                  >
                    <FormLabel>Description (English)</FormLabel>
                    <Textarea {...field} maxLength={75} />
                    <FormErrorMessage>
                      {form.errors.description?.en}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="description.fr">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.description?.fr &&
                      form.touched.description?.fr
                    }
                  >
                    <FormLabel>Description (French)</FormLabel>
                    <Textarea {...field} maxLength={75} />
                    <FormErrorMessage>
                      {form.errors.description?.fr}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>

            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={6}
              width="100%"
            >
              <Field name="websiteUrl">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.websiteUrl && form.touched.websiteUrl
                    }
                  >
                    <FormLabel>Website URL</FormLabel>
                    <Input {...field} type="url" />
                    <FormErrorMessage>
                      {form.errors.websiteUrl}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="googleProfileUrl">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.googleProfileUrl &&
                      form.touched.googleProfileUrl
                    }
                  >
                    <FormLabel>Google Profile URL</FormLabel>
                    <Input {...field} type="url" />
                    <FormErrorMessage>
                      {form.errors.googleProfileUrl}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>

            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={6}
              width="100%"
            >
              <Field name="category.en">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.category?.en && form.touched.category?.en
                    }
                    isRequired
                  >
                    <FormLabel>Category</FormLabel>
                    <Select
                      {...field}
                      placeholder="Select category"
                      onChange={(e) => {
                        const selectedCategory = businessCategories.find(
                          (cat) => cat.en === e.target.value
                        );
                        form.setFieldValue("category", {
                          en: selectedCategory.en,
                          fr: selectedCategory.fr,
                        });
                      }}
                    >
                      {businessCategories.map((category) => (
                        <option key={category.en} value={category.en}>
                          {`${category.en} / ${category.fr}`}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>
                      {form.errors.category?.en}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <FormControl>
                <FormLabel>Logo</FormLabel>
                <Input
                  type="file"
                  accept="image/*"
                  onChange={handleLogoChange}
                  display="none"
                  id="logo-upload"
                />
                <Button
                  as="label"
                  htmlFor="logo-upload"
                  cursor="pointer"
                  width="100%"
                >
                  Upload Logo
                </Button>
                {logoPreview && (
                  <Box mt={2}>
                    <Image
                      src={logoPreview}
                      alt="Logo preview"
                      maxH="100px"
                      objectFit="contain"
                    />
                  </Box>
                )}
              </FormControl>
            </Stack>
          </VStack>
        );

      case 1: // Contact
        return (
          <VStack spacing={6} width="100%">
            <Field name="email">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.email && form.touched.email}
                  isRequired
                >
                  <FormLabel>Email</FormLabel>
                  <Input {...field} type="email" />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="phoneNumber">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.phoneNumber && form.touched.phoneNumber
                  }
                  isRequired
                >
                  <FormLabel>Phone Number</FormLabel>
                  <PhoneNumberInput field={field} />
                  <FormErrorMessage>{form.errors.phoneNumber}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </VStack>
        );

      case 2: // Coupon
        return (
          <VStack spacing={6} width="100%">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={6}
              width="100%"
            >
              <Field name="couponDetails.en">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.couponDetails?.en &&
                      form.touched.couponDetails?.en
                    }
                    isRequired
                  >
                    <FormLabel>Coupon Details (English)</FormLabel>
                    <Textarea
                      {...field}
                      placeholder="Ex: 10% off any purchase of 50$ or more."
                    />
                    <FormErrorMessage>
                      {form.errors.couponDetails?.en}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="couponDetails.fr">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.couponDetails?.fr &&
                      form.touched.couponDetails?.fr
                    }
                  >
                    <FormLabel>Coupon Details (French)</FormLabel>
                    <Textarea
                      {...field}
                      placeholder="Ex: 10% de rabais sur tout achat de 50$ ou plus."
                    />
                    <FormErrorMessage>
                      {form.errors.couponDetails?.fr}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>

            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={6}
              width="100%"
            >
              <Field name="termsAndExclusions.en">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.termsAndExclusions?.en &&
                      form.touched.termsAndExclusions?.en
                    }
                  >
                    <FormLabel>Terms and Exclusions (English)</FormLabel>
                    <Textarea
                      {...field}
                      placeholder="Ex: Only valid on Mondays."
                    />
                    <FormErrorMessage>
                      {form.errors.termsAndExclusions?.en}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="termsAndExclusions.fr">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.termsAndExclusions?.fr &&
                      form.touched.termsAndExclusions?.fr
                    }
                  >
                    <FormLabel>Terms and Exclusions (French)</FormLabel>
                    <Textarea
                      {...field}
                      placeholder="Ex: Valide les lundis seulement."
                    />
                    <FormErrorMessage>
                      {form.errors.termsAndExclusions?.fr}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>
          </VStack>
        );

      case 3: // Security
        return (
          <VStack spacing={6} width="100%">
            <Field name="passcode">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.passcode && form.touched.passcode}
                  isRequired
                >
                  <FormLabel>Passcode</FormLabel>
                  <Input {...field} type="password" />
                  <FormErrorMessage>{form.errors.passcode}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="confirmPasscode">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.confirmPasscode && form.touched.confirmPasscode
                  }
                  isRequired
                >
                  <FormLabel>Confirm Passcode</FormLabel>
                  <Input {...field} type="password" />
                  <FormErrorMessage>
                    {form.errors.confirmPasscode}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </VStack>
        );

      default:
        return null;
    }
  };

  return (
    <Form>
      <VStack spacing={{ base: 6, md: 8 }} width="100%">
        <FormStepper steps={steps} activeStep={activeStep} />
        <Box width="100%" pt={{ base: 4, md: 10 }}>
          {renderStepContent(activeStep)}
        </Box>
        <HStack
          justify="space-between"
          width="100%"
          pt={{ base: 4, md: 6 }}
          flexDir={{ base: "column", sm: "row" }}
          spacing={{ base: 4, sm: 0 }}
        >
          <Button
            variant="ghost"
            onClick={() => setActiveStep(Math.max(activeStep - 1, 0))}
            isDisabled={activeStep === 0}
            width={{ base: "100%", sm: "auto" }}
          >
            Previous
          </Button>
          <Button
            variant="primary"
            type="submit"
            isLoading={isSubmitting}
            width={{ base: "100%", sm: "auto" }}
          >
            {activeStep === steps.length - 1
              ? isEditMode
                ? "Update Business"
                : "Add Business"
              : "Next"}
          </Button>
        </HStack>
      </VStack>
    </Form>
  );
};

export default BusinessFormContent;
