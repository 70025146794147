import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  FormErrorMessage,
  Select,
  useToast,
  Heading,
  Stack,
  HStack,
  Text,
  Image,
  Spinner,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { db } from "../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Navbar from "../components/Navbar";
import { apiUrl } from "../config";
import FormStepper from "../components/FormStepper";
import PhoneNumberInput from "../components/PhoneNumberInput";
import { businessCategories } from "../constants/businessCategories";
import BusinessFormContent, { steps } from "../components/BusinessFormContent";

const ExternalBusinessForm = () => {
  const { businessId } = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useNavigate();
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(true);
  const [isEligible, setIsEligible] = useState(false);
  const [businessData, setBusinessData] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [logo, setLogo] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);

  const initialValues = {
    name: { en: "", fr: "" },
    description: { en: "", fr: "" },
    websiteUrl: "",
    googleProfileUrl: "",
    category: { en: "", fr: "" },
    email: "",
    phoneNumber: "",
    couponDetails: { en: "", fr: "" },
    termsAndExclusions: { en: "", fr: "" },
    passcode: "",
    confirmPasscode: "",
  };

  const validationSchemas = [
    // Step 1: Business Info
    Yup.object().shape({
      name: Yup.object().shape({
        en: Yup.string().required("Business name (English) is required"),
        fr: Yup.string(),
      }),
      description: Yup.object().shape({
        en: Yup.string()
          .max(75, "Description must be at most 75 characters")
          .required("Business description (English) is required"),
        fr: Yup.string().max(75, "Description must be at most 75 characters"),
      }),
      websiteUrl: Yup.string().url("Invalid URL").nullable().default(""),
      googleProfileUrl: Yup.string().url("Invalid URL").nullable().default(""),
      category: Yup.object().shape({
        en: Yup.string().required("Category (English) is required"),
        fr: Yup.string().required("Category (French) is required"),
      }),
    }),
    // Step 2: Contact
    Yup.object().shape({
      email: Yup.string().email("Invalid email").required("Email is required"),
      phoneNumber: Yup.string()
        .matches(/^\d{10}$/, "Phone number must be 10 digits")
        .required("Phone number is required"),
    }),
    // Step 3: Coupon
    Yup.object().shape({
      couponDetails: Yup.object().shape({
        en: Yup.string().required("Coupon details (English) is required"),
        fr: Yup.string(),
      }),
      termsAndExclusions: Yup.object().shape({
        en: Yup.string(),
        fr: Yup.string(),
      }),
    }),
    // Step 4: Security
    Yup.object().shape({
      passcode: Yup.string()
        .min(4, "Passcode must be at least 4 characters")
        .required("Passcode is required"),
      confirmPasscode: Yup.string()
        .oneOf([Yup.ref("passcode"), null], "Passcodes must match")
        .required("Confirm passcode is required"),
    }),
  ];

  useEffect(() => {
    const verifySubmission = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/verify-business-submission/${businessId}?token=${token}`
        );

        if (response.data.eligible) {
          setIsEligible(true);
          setBusinessData(response.data.businessData);
          // Pre-fill email from the business data
          initialValues.email = response.data.businessData.email;
        }
      } catch (error) {
        toast({
          title: "Error",
          description:
            error.response?.data?.message || "Invalid or expired link",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setIsEligible(false);
      } finally {
        setIsLoading(false);
      }
    };

    verifySubmission();
  }, [businessId, token, toast]);

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLogo(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (values, actions) => {
    try {
      const formData = new FormData();

      // Add all the nested objects as stringified JSON
      formData.append("name", JSON.stringify(values.name));
      formData.append("description", JSON.stringify(values.description));
      formData.append("category", JSON.stringify(values.category));
      formData.append("couponDetails", JSON.stringify(values.couponDetails));
      formData.append(
        "termsAndExclusions",
        JSON.stringify(values.termsAndExclusions)
      );

      // Add regular fields
      formData.append("websiteUrl", values.websiteUrl || "");
      formData.append("googleProfileUrl", values.googleProfileUrl || "");
      formData.append("email", values.email);
      formData.append("phoneNumber", values.phoneNumber);
      formData.append("passcode", values.passcode);

      // Add businessId and token for verification
      formData.append("businessId", businessId);
      formData.append("token", token);

      // Add logo if exists
      if (logo) {
        formData.append("logo", logo);
      }

      // Submit the form - fix the URL to match the endpoint
      const response = await axios.post(`${apiUrl}/submit-business`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast({
        title: "Success",
        description: "Business information submitted successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Navigate to a success page or show a success message
      navigate("/submission-success");
    } catch (error) {
      console.error("Submission error:", error);
      toast({
        title: "Error",
        description:
          error.response?.data?.error ||
          "Failed to submit business information",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    actions.setSubmitting(false);
  };

  if (isLoading) {
    return (
      <Box
        minH="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (!isEligible) {
    return (
      <Box
        minH="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Alert
          status="error"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <Text mt={4}>This link is invalid or has expired.</Text>
        </Alert>
      </Box>
    );
  }

  return (
    <>
      <Navbar />
      <Box minH="calc(100vh - 60px)" bg="primaryBackground" pt="60px" px={4}>
        <VStack align="center" maxWidth="800px" margin="0 auto" pt={8}>
          <Heading size="lg">Submit Your Business Coupon</Heading>
          <Box
            width="100%"
            bg="secondaryBackground"
            p={{ base: 4, sm: 6, md: 8, lg: 12 }}
            borderRadius="xl"
            boxShadow="md"
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[activeStep]}
              onSubmit={async (values, actions) => {
                if (activeStep === steps.length - 1) {
                  await handleSubmit(values, actions);
                } else {
                  setActiveStep(activeStep + 1);
                  actions.setTouched({});
                  actions.setSubmitting(false);
                }
              }}
            >
              {(props) => (
                <BusinessFormContent
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  handleLogoChange={handleLogoChange}
                  logoPreview={logoPreview}
                  setLogoPreview={setLogoPreview}
                  isEditMode={false}
                  isSubmitting={props.isSubmitting}
                />
              )}
            </Formik>
          </Box>
        </VStack>
      </Box>
    </>
  );
};

export default ExternalBusinessForm;
