import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  FormErrorMessage,
  Heading,
  Text,
  Image,
  Flex,
  Link,
  Container,
  Stepper,
  StepIndicator,
  StepStatus,
  StepIcon,
  StepNumber,
  StepSeparator,
  StepTitle,
  HStack,
  Step,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { db } from "../firebase";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom";
import logo from "../assets/logos/logo.png";
import { apiUrl } from "../config";
import { useLanguage } from "../contexts/LanguageContext";
import Navbar from "../components/Navbar"; // Import the Navbar component
import FormStepper from "../components/FormStepper";
import PhoneNumberInput from "../components/PhoneNumberInput";

const steps = [{ title: "Campaign Selection" }, { title: "Customer Info" }];

const AddCustomer = () => {
  const navigate = useNavigate();
  const { campaignId, agentId, lang } = useParams();
  const { language, setLanguage, translate } = useLanguage();
  const [campaigns, setCampaigns] = useState([]);
  const [agents, setAgents] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [isCampaignActive, setIsCampaignActive] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedAgent, setSelectedAgent] = useState(null);

  const getLocalizedValue = (value) => {
    if (typeof value === "object" && value !== null) {
      return language === "fr" && value.fr ? value.fr : value.en || "";
    }
    return value || "";
  };

  useEffect(() => {
    const fetchCampaigns = async () => {
      const campaignsRef = collection(db, "campaigns");
      const querySnapshot = await getDocs(campaignsRef);
      const campaignsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name, // Store the whole name object
      }));
      setCampaigns(campaignsData);
    };

    fetchCampaigns();

    if (campaignId) {
      fetchCampaignDetails(campaignId);
      fetchAgents(campaignId);
    }

    // Set language based on the 'lang' parameter
    if (lang === "fr") {
      setLanguage("fr");
    } else {
      setLanguage("en");
    }
  }, [campaignId, lang, setLanguage]);

  useEffect(() => {
    const fetchData = async () => {
      if (campaignId) {
        await fetchCampaignDetails(campaignId);
        await fetchAgents(campaignId);

        // If agentId is provided, fetch agent details
        if (agentId) {
          const agentDoc = await getDoc(
            doc(db, "campaigns", campaignId, "agents", agentId)
          );
          if (agentDoc.exists()) {
            setSelectedAgent(agentDoc.data());
          }
        }
      }
    };

    fetchData();
  }, [campaignId, agentId]);

  const initialValues = {
    campaignId: campaignId || "",
    agentId: agentId || "",
    name: "",
    email: "",
    confirmEmail: "",
    phoneNumber: "",
    language: language || "en",
    agreeToTerms: false,
  };

  const handleSubmit = async (values, actions) => {
    console.log("Values:", values);
    try {
      await axios
        .post(`${apiUrl}/create-checkout-session`, values)
        .then((response) => {
          if (response.status === 200) {
            window.location = response.data.url;
          } else {
            return Promise.reject(response.data);
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
      console.log("Customer added successfully");
      actions.setSubmitting(false);
      actions.resetForm();
    } catch (error) {
      console.error("Error adding customer:", error);
      actions.setSubmitting(false);
    }
  };

  const fetchCampaignDetails = async (campaignId) => {
    const campaignRef = doc(db, "campaigns", campaignId);
    const campaignSnap = await getDoc(campaignRef);
    if (campaignSnap.exists()) {
      const data = campaignSnap.data();
      setSelectedCampaign({
        id: campaignSnap.id,
        ...data,
      });
      setIsCampaignActive(data.active || false);
    } else {
      setSelectedCampaign(null);
      setIsCampaignActive(false);
    }
  };

  const fetchAgents = async (campaignId) => {
    const agentsRef = collection(db, "campaigns", campaignId, "agents");
    const agentsSnapshot = await getDocs(agentsRef);
    const agentsData = agentsSnapshot.docs.map((doc) => ({
      id: doc.id,
      name: doc.data().name,
    }));
    setAgents(agentsData);
  };

  const renderStepContent = (step, formikProps) => {
    switch (step) {
      case 0:
        return (
          <VStack spacing={6} width="100%">
            {selectedCampaign && (
              <Box width="100%" p={6} bg="gray.50" borderRadius="xl">
                <Heading size="md" mb={3}>
                  {language === "fr" && selectedCampaign.name.fr
                    ? selectedCampaign.name.fr
                    : selectedCampaign.name.en}
                </Heading>
                <Text>
                  {language === "fr" && selectedCampaign.description.fr
                    ? selectedCampaign.description.fr
                    : selectedCampaign.description.en}
                </Text>
                <Text mt={3} fontWeight="bold">
                  {translate("addCustomer.couponBookPrice")}: $
                  {selectedCampaign.couponBookPrice}
                </Text>
                <Button
                  onClick={() => {
                    if (selectedCampaign?.offeringURL) {
                      window.open(selectedCampaign.offeringURL, "_blank");
                    } else {
                      navigate(`/businesses/${selectedCampaign.id}`);
                    }
                  }}
                  variant="secondary"
                  mt={4}
                  size="sm"
                >
                  {translate("addCustomer.seeOfferingsButton")}
                </Button>
              </Box>
            )}

            {!campaignId && (
              <Field name="campaignId">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.campaignId && form.touched.campaignId
                    }
                    isRequired
                  >
                    <FormLabel>{translate("addCustomer.campaign")}</FormLabel>
                    <Select
                      {...field}
                      placeholder={translate("addCustomer.selectCampaign")}
                      onChange={(e) => {
                        field.onChange(e);
                        fetchCampaignDetails(e.target.value);
                        fetchAgents(e.target.value);
                      }}
                    >
                      {campaigns.map((campaign) => (
                        <option key={campaign.id} value={campaign.id}>
                          {getLocalizedValue(campaign.name)}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>
                      {form.errors.campaignId}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            )}

            {!agentId && formikProps.values.campaignId && (
              <Field name="agentId">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.agentId && form.touched.agentId}
                    isRequired
                  >
                    <FormLabel>{translate("addCustomer.fundraiser")}</FormLabel>
                    <Select
                      {...field}
                      placeholder={translate("addCustomer.selectFundraiser")}
                    >
                      {agents.map((agent) => (
                        <option key={agent.id} value={agent.id}>
                          {agent.name}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{form.errors.agentId}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            )}

            {agentId && selectedAgent && (
              <Box width="100%" p={4} bg="gray.50" borderRadius="md">
                <Text fontWeight="medium">
                  {translate("addCustomer.fundraiser")}: {selectedAgent.name}
                </Text>
              </Box>
            )}
          </VStack>
        );

      case 1:
        return (
          <VStack spacing={4} width="100%">
            <Field name="name">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.name && form.touched.name}>
                  <FormLabel>{translate("addCustomer.name")}</FormLabel>
                  <Input
                    {...field}
                    placeholder={translate("addCustomer.namePlaceholder")}
                  />
                  <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="email">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.email && form.touched.email}
                >
                  <FormLabel>{translate("addCustomer.email")}</FormLabel>
                  <Input
                    {...field}
                    placeholder={translate("addCustomer.emailPlaceholder")}
                  />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="confirmEmail">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.confirmEmail && form.touched.confirmEmail
                  }
                >
                  <FormLabel>{translate("addCustomer.confirmEmail")}</FormLabel>
                  <Input
                    {...field}
                    placeholder={translate(
                      "addCustomer.confirmEmailPlaceholder"
                    )}
                  />
                  <FormErrorMessage>
                    {form.errors.confirmEmail}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="phoneNumber">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.phoneNumber && form.touched.phoneNumber
                  }
                >
                  <FormLabel>{translate("addCustomer.phoneNumber")}</FormLabel>
                  <PhoneNumberInput field={field} />
                  <FormErrorMessage>{form.errors.phoneNumber}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="agreeToTerms">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.agreeToTerms && form.touched.agreeToTerms
                  }
                >
                  <Flex alignItems="flex-start">
                    <input
                      type="checkbox"
                      {...field}
                      style={{ marginRight: "8px", marginTop: "4px" }}
                    />
                    <FormLabel mb={0}>
                      {translate("addCustomer.agreeToTerms")}{" "}
                      <Link
                        as={RouterLink}
                        to="/terms"
                        color="blue.500"
                        isExternal
                      >
                        {translate("addCustomer.termsAndConditions")}
                      </Link>{" "}
                      {translate("addCustomer.and")}{" "}
                      <Link
                        as={RouterLink}
                        to="/privacy"
                        color="blue.500"
                        isExternal
                      >
                        {translate("addCustomer.privacyPolicy")}
                      </Link>
                    </FormLabel>
                  </Flex>
                  <FormErrorMessage>
                    {form.errors.agreeToTerms}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </VStack>
        );
      default:
        return null;
    }
  };

  // Define validation schemas here, after translate is available
  const validationSchemas = [
    // Step 0: Campaign Selection
    Yup.object().shape({
      campaignId: Yup.string().required(
        translate("addCustomer.validationErrors.campaignRequired")
      ),
      agentId: Yup.string().required(
        translate("addCustomer.validationErrors.fundraiserRequired")
      ),
    }),
    // Step 1: Customer Information
    Yup.object().shape({
      name: Yup.string().required(
        translate("addCustomer.validationErrors.nameRequired")
      ),
      email: Yup.string()
        .email(translate("addCustomer.validationErrors.invalidEmail"))
        .required(translate("addCustomer.validationErrors.emailRequired")),
      confirmEmail: Yup.string()
        .oneOf(
          [Yup.ref("email"), null],
          translate("addCustomer.validationErrors.emailsMustMatch")
        )
        .required(
          translate("addCustomer.validationErrors.confirmEmailRequired")
        ),
      phoneNumber: Yup.string()
        .matches(
          /^\d{10}$/,
          translate("addCustomer.validationErrors.invalidPhone")
        )
        .required(translate("addCustomer.validationErrors.phoneRequired")),
      agreeToTerms: Yup.boolean()
        .oneOf(
          [true],
          translate("addCustomer.validationErrors.agreeToTermsRequired")
        )
        .required(
          translate("addCustomer.validationErrors.agreeToTermsRequired")
        ),
    }),
  ];

  return (
    <Box minH="100vh" bg="primaryBackground">
      <Navbar external={true} />
      <Container maxW="container.xl" centerContent pt={{ base: 4, md: 8 }}>
        <Heading
          as="h1"
          size="xl"
          mb={10}
          mt={20}
          textAlign="center"
          color="heading"
        >
          {selectedCampaign
            ? getLocalizedValue(selectedCampaign.name)
            : translate("addCustomer.heading")}
        </Heading>

        <Box
          width="100%"
          maxW="600px"
          bg="secondaryBackground"
          p={8}
          borderRadius="xl"
          boxShadow="md"
        >
          {selectedCampaign && !isCampaignActive ? (
            <VStack spacing={4} align="center">
              <Text color="red.500" fontWeight="bold">
                {translate("addCustomer.campaignInactive")}
              </Text>
              <Button
                variant="secondary"
                onClick={() => window.location.reload()}
              >
                {translate("addCustomer.backButton")}
              </Button>
            </VStack>
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[activeStep]}
              onSubmit={async (values, actions) => {
                if (activeStep === steps.length - 1) {
                  await handleSubmit(values, actions);
                } else {
                  const isValid = await actions.validateForm();
                  if (Object.keys(isValid).length === 0) {
                    setActiveStep(activeStep + 1);
                    actions.setTouched({});
                    actions.setSubmitting(false);
                  }
                }
              }}
            >
              {(props) => (
                <Form>
                  <VStack spacing={8} width="100%">
                    <FormStepper steps={steps} activeStep={activeStep} />

                    <Box width="100%" pt={6}>
                      {renderStepContent(activeStep, props)}
                    </Box>

                    <HStack justify="space-between" width="100%" pt={4}>
                      <Button
                        variant="ghost"
                        onClick={() =>
                          setActiveStep(Math.max(activeStep - 1, 0))
                        }
                        isDisabled={activeStep === 0}
                      >
                        {translate("common.previous")}
                      </Button>
                      <Button
                        variant="primary"
                        type="submit"
                        isLoading={props.isSubmitting}
                      >
                        {activeStep === steps.length - 1
                          ? translate("addCustomer.proceedButton")
                          : translate("common.next")}
                      </Button>
                    </HStack>

                    {activeStep === steps.length - 1 && (
                      <Flex alignItems="center" justifyContent="center" mt={2}>
                        <Text fontSize="sm" color="gray.500" mr={2}>
                          {translate("addCustomer.securelyPoweredBy")}
                        </Text>
                        <Image
                          src="https://stripe.com/img/v3/home/twitter.png"
                          alt="Stripe logo"
                          height="20px"
                        />
                      </Flex>
                    )}
                  </VStack>
                </Form>
              )}
            </Formik>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default AddCustomer;
