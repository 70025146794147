import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Heading,
  Text,
  VStack,
  Flex,
  Spinner,
  useToast,
  SimpleGrid,
  Icon,
  HStack,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  useBreakpointValue,
  IconButton,
  Badge,
} from "@chakra-ui/react";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { db } from "../firebase";
import Navbar from "../components/Navbar";
import {
  FaChevronRight,
  FaPlus,
  FaMegaphone,
  FaUsers,
  FaHandshake,
  FaDollarSign,
  FaMoneyCheckAlt,
  FaExternalLinkAlt,
  FaCheckCircle,
  FaExclamationCircle,
  FaCog,
  FaExclamationTriangle,
  FaTrash,
} from "react-icons/fa";
import { VscMegaphone } from "react-icons/vsc";
import axios from "axios";
import { apiUrl } from "../config";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { UserAuth } from "../contexts/AuthContext";
import PendingApproval from "./PendingApproval";
import { ArrowBackIcon } from "@chakra-ui/icons";

const OrganizationHome = () => {
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [organization, setOrganization] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const cardBg = useColorModeValue("white", "gray.700");
  const [stats, setStats] = useState({
    totalRaised: 0,
    totalAgents: 0,
    totalSupporters: 0,
  });
  const [isAddAgentModalOpen, setIsAddAgentModalOpen] = useState(false);
  const [newAgentEmail, setNewAgentEmail] = useState("");
  const [addAgentError, setAddAgentError] = useState("");
  const [isAddingAgent, setIsAddingAgent] = useState(false);
  const [agents, setAgents] = useState([]);
  const [loadingAgents, setLoadingAgents] = useState(true);
  const [isRemovingAgent, setIsRemovingAgent] = useState(false);
  const [agentToRemove, setAgentToRemove] = useState(null);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const { user } = UserAuth();
  const buttonSize = useBreakpointValue({ base: "sm", md: "sm" });

  useEffect(() => {
    const fetchOrganizationAndCampaigns = async () => {
      try {
        // Fetch organization and check if user is admin
        const orgDoc = await getDoc(doc(db, "organizations", organizationId));
        if (!orgDoc.exists()) {
          toast({
            title: "Error",
            description: "Organization not found",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          navigate("/");
          return;
        }

        const orgData = orgDoc.data();
        setOrganization({
          id: orgDoc.id,
          ...orgData,
          approved: orgData.approved ?? false,
        });

        // Check if user is admin
        const agentDoc = await getDoc(
          doc(db, "organizations", organizationId, "agents", user.uid)
        );
        const userIsAdmin =
          agentDoc.exists() && agentDoc.data().isAdmin === true;
        setIsAdmin(userIsAdmin);

        // Fetch user's campaigns
        const userDoc = await getDoc(doc(db, "users", user.uid));
        const userData = userDoc.data();
        const userCampaigns = userData.campaigns || [];

        // Fetch campaigns
        const campaignPromises = userCampaigns.map((campaignId) =>
          getDoc(doc(db, "campaigns", campaignId))
        );
        const campaignDocs = await Promise.all(campaignPromises);
        const campaignsData = campaignDocs
          .filter(
            (doc) =>
              doc.exists() && doc.data().organizationId === organizationId
          )
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
        setCampaigns(campaignsData);

        // Only calculate stats if user is admin
        if (userIsAdmin) {
          const stats = campaignsData.reduce(
            (acc, campaign) => ({
              totalRaised: acc.totalRaised + (campaign.totalRaised || 0),
              totalAgents: acc.totalAgents + (campaign.agents?.length || 0),
              totalSupporters:
                acc.totalSupporters + (campaign.supporters?.length || 0),
            }),
            { totalRaised: 0, totalAgents: 0, totalSupporters: 0 }
          );
          setStats(stats);
        }

        // Fetch administrators from agents subcollection
        const agentsSnapshot = await getDocs(
          collection(db, "organizations", organizationId, "agents")
        );

        const agentsData = agentsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          status: "active",
        }));

        // Fetch pending invites if user is admin
        let pendingInvitesData = [];
        if (userIsAdmin) {
          const pendingInvitesSnapshot = await getDocs(
            query(
              collection(db, "pendingInvites"),
              where("organizationId", "==", organizationId),
              where("type", "==", "organization"),
              where("status", "==", "pending")
            )
          );

          pendingInvitesData = pendingInvitesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            status: "pending",
          }));

          console.log("Pending invites found:", pendingInvitesData);
        }

        // Combine agents and pending invites
        setAgents([...agentsData, ...pendingInvitesData]);
        setLoadingAgents(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast({
          title: "Error",
          description: "Failed to load organization details",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchOrganizationAndCampaigns();
  }, [organizationId, user, navigate, toast]);

  const handleCreateCampaign = () => {
    navigate(`/create-campaign/${organizationId}`);
  };

  const handleCampaignClick = (campaignId) => {
    navigate(`/campaign/${campaignId}`);
  };

  const handleInitializeStripe = async () => {
    try {
      setLoading(true);
      const idToken = sessionStorage.getItem("idToken");

      // Create Stripe account
      const accountResponse = await axios.post(
        `${apiUrl}/create_stripe_account`,
        {
          organizationId,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      const accountId = accountResponse.data.account;

      // Create account link for onboarding
      const linkResponse = await axios.post(
        `${apiUrl}/link_stripe_account`,
        {
          account: accountId,
          organizationId: organizationId,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      // Redirect to Stripe onboarding
      window.location.href = linkResponse.data.url;
    } catch (error) {
      console.error("Error initializing Stripe:", error);
      toast({
        title: "Error",
        description: "Failed to initialize Stripe account. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleBackClick = () => {
    navigate(`/home`);
  };

  const handleDashboardClick = async () => {
    try {
      const idToken = sessionStorage.getItem("idToken");
      const response = await axios.post(
        `${apiUrl}/create-dashboard-link`,
        {
          accountId: organization.stripeAccountId,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      window.open(response.data.url, "_blank");
    } catch (error) {
      console.error("Error creating dashboard link:", error);
      toast({
        title: "Error",
        description: "Failed to open Stripe dashboard. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleAddAgent = async (values, actions) => {
    try {
      setIsAddingAgent(true);
      const idToken = sessionStorage.getItem("idToken");

      const response = await axios.post(
        `${apiUrl}/organizations/${organizationId}/agents`,
        values,
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );

      if (response.data.status === "pending") {
        setAgents((prevAgents) => [
          ...prevAgents,
          {
            id: response.data.inviteId,
            email: values.email,
            status: "pending",
            type: "organization",
            createdAt: new Date(),
            createdBy: user.uid,
            organizationId: organizationId,
            isAdmin: true, // Organization agents are always admin
          },
        ]);

        toast({
          title: "Invitation Sent",
          description: "An invitation has been sent to the email address",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        setAgents((prevAgents) => [...prevAgents, response.data.agent]);
        toast({
          title: "Administrator Added",
          description: "The administrator has been added successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }

      actions.setSubmitting(false);
      setIsAddAgentModalOpen(false);
    } catch (error) {
      console.error("Error adding agent:", error);
      toast({
        title: "Error",
        description:
          error.response?.data?.error || "Failed to add administrator",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      actions.setSubmitting(false);
    } finally {
      setIsAddingAgent(false);
    }
  };

  const handleRemoveAgent = async (agentId) => {
    try {
      setIsRemovingAgent(true);
      const idToken = sessionStorage.getItem("idToken");

      await axios.delete(
        `${apiUrl}/organizations/${organizationId}/agents/${agentId}`,
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );

      setAgents(agents.filter((agent) => agent.id !== agentId));

      toast({
        title: "Success",
        description: "Administrator removed successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error removing agent:", error);
      toast({
        title: "Error",
        description: "Failed to remove administrator. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsRemovingAgent(false);
      setIsRemoveModalOpen(false);
      setAgentToRemove(null);
    }
  };

  const initiateRemoveAgent = (agent) => {
    setAgentToRemove(agent);
    setIsRemoveModalOpen(true);
  };

  const confirmRemoveAgent = async () => {
    if (!agentToRemove) return;

    try {
      setIsRemovingAgent(true);
      await handleRemoveAgent(agentToRemove.id);
      setIsRemoveModalOpen(false);
      setAgentToRemove(null);
    } finally {
      setIsRemovingAgent(false);
    }
  };

  const deletePendingInvite = async (agent) => {
    try {
      const idToken = sessionStorage.getItem("idToken");
      await axios.delete(`${apiUrl}/pendingInvites`, {
        headers: { Authorization: `Bearer ${idToken}` },
        data: {
          type: "organization",
          id: organizationId,
          email: agent.email,
        },
      });

      // Update local state to remove the deleted invite
      setAgents(agents.filter((a) => a.id !== agent.id));

      toast({
        title: "Success",
        description: "Invitation deleted successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting invitation:", error);
      toast({
        title: "Error",
        description:
          error.response?.data?.message || "Failed to delete invitation",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Add validation schema
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });

  if (loading) {
    return (
      <>
        <Navbar />
        <Flex minH="100vh" justify="center" align="center">
          <Spinner size="xl" />
        </Flex>
      </>
    );
  }

  if (!organization?.approved) {
    return <PendingApproval />;
  }

  return (
    <>
      <Navbar />
      <Box
        minH="calc(100vh - 60px)"
        bg="primaryBackground"
        pt="60px"
        px={{ base: 4, md: 10 }}
      >
        <Container maxW="1200px">
          <Flex
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "space-between" }}
            align={{ base: "center", md: "flex-start" }}
            gap={4}
            mb={8}
          >
            <Box textAlign={{ base: "center", md: "left" }}>
              <Heading as="h1" size="lg" color="heading">
                Organization Dashboard
              </Heading>
              <Text color="text" fontSize="xl">
                {organization?.name?.en || organization?.name}
              </Text>
            </Box>
          </Flex>

          <VStack spacing={8} align="stretch">
            <Box
              width="100%"
              bg="white"
              p={{ base: 6, md: 8 }}
              borderRadius="xl"
              boxShadow="md"
            >
              <Flex align="center" justify="space-between" mb={6}>
                <HStack spacing={3}>
                  <Icon as={VscMegaphone} boxSize={6} color="black" />
                  <Heading as="h2" size="md" color="heading">
                    Campaigns
                  </Heading>
                </HStack>
                {isAdmin && (
                  <Button
                    leftIcon={<FaPlus />}
                    variant="primary"
                    size="sm"
                    onClick={handleCreateCampaign}
                  >
                    New Campaign
                  </Button>
                )}
              </Flex>

              <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
                {campaigns.map((campaign) => (
                  <Box
                    key={campaign.id}
                    p={5}
                    bg="white"
                    borderRadius="lg"
                    border="1px"
                    borderColor="gray.200"
                    boxShadow="sm"
                    cursor="pointer"
                    onClick={() => handleCampaignClick(campaign.id)}
                    _hover={{
                      transform: "translateY(-2px)",
                      transition: "all 0.2s",
                      boxShadow: "md",
                    }}
                    position="relative"
                    role="group"
                  >
                    <VStack align="start" spacing={2} pr={6}>
                      <Heading size="sm" color="heading" fontWeight="medium">
                        {campaign.name?.en ||
                          campaign.name ||
                          "Unnamed Campaign"}
                      </Heading>
                    </VStack>
                    <Icon
                      as={FaChevronRight}
                      position="absolute"
                      top="50%"
                      right={4}
                      transform="translateY(-50%)"
                      color="gray.400"
                      _groupHover={{ color: "gray.600" }}
                    />
                  </Box>
                ))}
                {campaigns.length === 0 && (
                  <Box
                    p={5}
                    bg="white"
                    borderRadius="lg"
                    border="1px"
                    borderColor="gray.200"
                    boxShadow="sm"
                    textAlign="center"
                  >
                    <Text color="gray.500">No campaigns</Text>
                  </Box>
                )}
              </SimpleGrid>
            </Box>

            <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8}>
              <Box
                bg={cardBg}
                p={6}
                borderRadius="lg"
                shadow="md"
                height="300px"
                overflowY="auto"
              >
                <Flex justify="space-between" align="center" mb={6}>
                  <HStack spacing={3}>
                    <Icon as={FaMoneyCheckAlt} boxSize={6} color="black" />
                    <Heading as="h2" size="md" color="heading">
                      Payouts
                    </Heading>
                  </HStack>
                </Flex>

                <VStack align="stretch" spacing={4}>
                  <Flex
                    justify="space-between"
                    align="center"
                    bg="white"
                    p={4}
                    borderRadius="md"
                    border="1px"
                    borderColor="gray.200"
                  >
                    <HStack spacing={4} align="center">
                      <Icon
                        as={
                          organization?.stripeConnected
                            ? FaCheckCircle
                            : FaExclamationTriangle
                        }
                        color={
                          organization?.stripeConnected
                            ? "green.500"
                            : "orange.500"
                        }
                        boxSize={6}
                      />
                      <Box textAlign="left">
                        <Text fontWeight="medium" color="heading">
                          {organization?.stripeConnected
                            ? "Payouts Enabled"
                            : "Payouts Not Set Up"}
                        </Text>
                        <Text fontSize="sm" color="gray.600">
                          {organization?.stripeConnected
                            ? "Your organization is ready to receive payments"
                            : "Set up your Stripe account to receive payments"}
                        </Text>
                      </Box>
                    </HStack>

                    {isAdmin && (
                      <Button
                        leftIcon={
                          organization?.stripeConnected ? (
                            <FaExternalLinkAlt />
                          ) : (
                            <FaCog />
                          )
                        }
                        variant={
                          organization?.stripeConnected ? "outline" : "primary"
                        }
                        size="sm"
                        onClick={
                          organization?.stripeConnected
                            ? handleDashboardClick
                            : handleInitializeStripe
                        }
                      >
                        {organization?.stripeConnected
                          ? "View Dashboard"
                          : "Set Up Payouts"}
                      </Button>
                    )}
                  </Flex>
                </VStack>
              </Box>
              <Box
                bg={cardBg}
                p={6}
                borderRadius="lg"
                shadow="md"
                height="300px"
                display="flex"
                flexDirection="column"
              >
                <Flex justify="space-between" align="center" mb={6}>
                  <HStack spacing={3}>
                    <Icon as={FaUsers} boxSize={6} color="black" />
                    <Heading as="h2" size="md" color="heading">
                      Administrators
                    </Heading>
                  </HStack>
                  {isAdmin && (
                    <Button
                      leftIcon={<FaPlus />}
                      variant="primary"
                      size="sm"
                      onClick={() => setIsAddAgentModalOpen(true)}
                    >
                      Add Administrator
                    </Button>
                  )}
                </Flex>

                <Box overflowY="auto">
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Name</Th>
                        <Th>Email</Th>
                        {isAdmin && <Th width="100px">Actions</Th>}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {agents.map((agent) => (
                        <Tr key={agent.id}>
                          <Td>
                            {agent.status === "pending" ? (
                              <Badge colorScheme="yellow" variant="subtle">
                                Pending Invite
                              </Badge>
                            ) : (
                              `${agent.firstName} ${agent.lastName}`
                            )}
                          </Td>
                          <Td>{agent.email}</Td>
                          {isAdmin && (
                            <Td>
                              {agent.status === "pending" ? (
                                <IconButton
                                  icon={<FaTrash />}
                                  aria-label="Delete Invitation"
                                  size="sm"
                                  variant="ghost"
                                  colorScheme="red"
                                  onClick={() => deletePendingInvite(agent)}
                                />
                              ) : (
                                <IconButton
                                  icon={<FaTrash />}
                                  aria-label="Remove Administrator"
                                  size="sm"
                                  variant="ghost"
                                  colorScheme="red"
                                  onClick={() => initiateRemoveAgent(agent)}
                                />
                              )}
                            </Td>
                          )}
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
              </Box>
            </SimpleGrid>
            <Box display="flex" justifyContent="flex-start">
              {organizationId && (
                <Button
                  leftIcon={<ArrowBackIcon />}
                  variant="ghost"
                  onClick={handleBackClick}
                  size="sm"
                >
                  Home
                </Button>
              )}
            </Box>
          </VStack>
        </Container>
      </Box>

      <Modal
        isOpen={isAddAgentModalOpen}
        onClose={() => setIsAddAgentModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Organization Administrator</ModalHeader>
          <ModalCloseButton />
          <Formik
            initialValues={{ email: "" }}
            validationSchema={validationSchema}
            onSubmit={handleAddAgent}
          >
            {(props) => (
              <Form>
                <ModalBody>
                  <VStack spacing={4}>
                    <Field name="email">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.email && form.touched.email}
                          isRequired
                        >
                          <FormLabel>Email</FormLabel>
                          <Input
                            {...field}
                            placeholder="Enter administrator's email"
                          />
                          <FormErrorMessage>
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </VStack>
                </ModalBody>
                <ModalFooter>
                  <Button
                    variant="ghost"
                    mr={3}
                    onClick={() => setIsAddAgentModalOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    isLoading={props.isSubmitting}
                  >
                    Add
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isRemoveModalOpen}
        onClose={() => setIsRemoveModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Remove Administrator</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to remove {agentToRemove?.name} from this
            organization?
          </ModalBody>
          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={() => setIsRemoveModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={confirmRemoveAgent}
              isLoading={isRemovingAgent}
            >
              Remove
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OrganizationHome;
