import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { UserAuth } from "../contexts/AuthContext";

const PasswordResetModal = ({ isOpen, onClose, initialEmail = "" }) => {
  const [resetEmail, setResetEmail] = useState(initialEmail);
  const [isResetting, setIsResetting] = useState(false);
  const { resetPassword } = UserAuth();
  const toast = useToast();

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    if (!resetEmail) return;

    setIsResetting(true);
    try {
      await resetPassword(resetEmail);
      toast({
        title: "Reset email sent",
        description: "Check your email for password reset instructions",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsResetting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Reset Password</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handlePasswordReset}>
          <ModalBody>
            <FormControl>
              <FormLabel>Email address</FormLabel>
              <Input
                type="email"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                placeholder="Enter your email"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button variant="secondary" type="submit" isLoading={isResetting}>
              Send Reset Link
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default PasswordResetModal;
