import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Heading,
  Textarea,
  Text,
  useToast,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  HStack,
  Stack,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { apiUrl } from "../config";
import Navbar from "../components/Navbar";
import { UserAuth } from "../contexts/AuthContext";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import FormStepper from "../components/FormStepper";
import PhoneNumberInput from "../components/PhoneNumberInput";

const steps = [
  { title: "Details" },
  { title: "Contact" },
  { title: "Pricing" },
  { title: "Offering" },
];

const validationSchemas = [
  // Step 1: Campaign Details
  Yup.object().shape({
    name: Yup.object().shape({
      en: Yup.string().required("Campaign name (English) is required"),
      fr: Yup.string(),
    }),
    description: Yup.object().shape({
      en: Yup.string()
        .max(1000, "Description must be at most 1000 characters")
        .required("Description (English) is required"),
      fr: Yup.string().max(1000, "Description must be at most 1000 characters"),
    }),
  }),
  // Step 2: Contact Info
  Yup.object().shape({
    contactName: Yup.string().required("Contact name is required"),
    contactEmail: Yup.string()
      .email("Invalid email")
      .required("Email is required"),
    contactPhone: Yup.string()
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
      .required("Phone number is required"),
  }),
  // Step 3: Pricing
  Yup.object().shape({
    couponBookPrice: Yup.number()
      .positive("Price must be positive")
      .required("Coupon book price is required"),
  }),
  // Step 4: Offering URL
  Yup.object().shape({
    offeringURL: Yup.string().url("Invalid URL").nullable(),
  }),
];

const CampaignForm = () => {
  const { campaignId, organizationId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const [activeStep, setActiveStep] = useState(0);
  const [initialValues, setInitialValues] = useState({
    name: { en: "", fr: "" },
    description: { en: "", fr: "" },
    contactName: "",
    contactEmail: "",
    contactPhone: "",
    couponBookPrice: "",
    offeringURL: "",
  });
  const { user } = UserAuth();

  const isEditMode = !!campaignId;

  useEffect(() => {
    const fetchCampaign = async () => {
      if (isEditMode) {
        const campaignDoc = await getDoc(doc(db, "campaigns", campaignId));
        if (campaignDoc.exists()) {
          const campaignData = campaignDoc.data();
          setInitialValues({
            ...campaignData,
            agents: {},
          });
        } else {
          toast({
            title: "Error",
            description: "Campaign not found",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          navigate(-1);
        }
      }
    };

    fetchCampaign();
  }, [campaignId, isEditMode, navigate, toast]);

  const handleSubmit = async (values, actions) => {
    try {
      const idToken = sessionStorage.getItem("idToken");
      const targetOrgId = organizationId || user.organizationId;
      let newCampaignId = campaignId;

      if (isEditMode) {
        await axios.put(
          `${apiUrl}/campaigns/${campaignId}`,
          {
            ...values,
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
      } else {
        const response = await axios.post(
          `${apiUrl}/addCampaign`,
          {
            ...values,
            organizationId: targetOrgId,
            userId: user.uid,
            userFirstName: user.firstName,
            userLastName: user.lastName,
            userEmail: user.email,
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        newCampaignId = response.data.campaignId;
      }

      actions.setSubmitting(false);
      toast({
        title: "Success",
        description: `Campaign ${
          isEditMode ? "updated" : "created"
        } successfully`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate(`/campaign/${newCampaignId}`);
    } catch (error) {
      console.error(
        `Error ${isEditMode ? "updating" : "creating"} campaign: `,
        error
      );
      actions.setSubmitting(false);
      toast({
        title: "Error",
        description: `Failed to ${
          isEditMode ? "update" : "create"
        } campaign. Please try again.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const renderStepContent = (step, formikProps) => {
    switch (step) {
      case 0:
        return (
          <VStack spacing={6} width="100%">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: 4, md: 6 }}
              width="100%"
            >
              <Field name="name.en">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.name?.en && form.touched.name?.en}
                    isRequired
                    flex={1}
                  >
                    <FormLabel>Campaign Name (English)</FormLabel>
                    <Input
                      {...field}
                      placeholder="Enter campaign name in English"
                    />
                    <FormErrorMessage>{form.errors.name?.en}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="name.fr">
                {({ field, form }) => (
                  <FormControl flex={1}>
                    <FormLabel>Campaign Name (French)</FormLabel>
                    <Input
                      {...field}
                      placeholder="Enter campaign name in French (optional)"
                    />
                    <FormErrorMessage>{form.errors.name?.fr}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>

            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: 4, md: 6 }}
              width="100%"
              alignItems="flex-start"
            >
              <Field name="description.en">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.description?.en &&
                      form.touched.description?.en
                    }
                    isRequired
                    flex={1}
                  >
                    <FormLabel>Description (English)</FormLabel>
                    <Textarea
                      {...field}
                      placeholder="Enter campaign description in English"
                      minHeight="150px"
                    />
                    <FormErrorMessage>
                      {form.errors.description?.en}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="description.fr">
                {({ field, form }) => (
                  <FormControl flex={1}>
                    <FormLabel>Description (French)</FormLabel>
                    <Textarea
                      {...field}
                      placeholder="Enter campaign description in French (optional)"
                      minHeight="150px"
                    />
                    <FormErrorMessage>
                      {form.errors.description?.fr}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>
          </VStack>
        );
      case 1:
        return (
          <VStack spacing={4}>
            <Field name="contactName">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.contactName && form.touched.contactName
                  }
                  isRequired
                >
                  <FormLabel>Contact Name</FormLabel>
                  <Input {...field} placeholder="Enter contact name" />
                  <FormErrorMessage>{form.errors.contactName}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="contactEmail">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.contactEmail && form.touched.contactEmail
                  }
                  isRequired
                >
                  <FormLabel>Contact Email</FormLabel>
                  <Input
                    {...field}
                    type="email"
                    placeholder="Enter contact email"
                  />
                  <FormErrorMessage>
                    {form.errors.contactEmail}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="contactPhone">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.contactPhone && form.touched.contactPhone
                  }
                  isRequired
                >
                  <FormLabel>Contact Phone</FormLabel>
                  <PhoneNumberInput field={field} />
                  <FormErrorMessage>
                    {form.errors.contactPhone}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </VStack>
        );
      case 2:
        return (
          <VStack spacing={4}>
            <Field name="couponBookPrice">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.couponBookPrice && form.touched.couponBookPrice
                  }
                  isRequired
                >
                  <FormLabel>Coupon Book Price (In CAD)</FormLabel>
                  <Input
                    {...field}
                    type="number"
                    step="0.01"
                    placeholder="Enter coupon book price"
                  />
                  <FormErrorMessage>
                    {form.errors.couponBookPrice}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </VStack>
        );
      case 3:
        return (
          <VStack spacing={4}>
            <Field name="offeringURL">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.offeringURL && form.touched.offeringURL
                  }
                >
                  <FormLabel>Offering Page URL (Optional)</FormLabel>
                  <Input
                    {...field}
                    type="url"
                    placeholder="Enter offering page URL"
                  />
                  <FormErrorMessage>{form.errors.offeringURL}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </VStack>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Navbar />
      <Box
        minH="calc(100vh - 60px)"
        bg="primaryBackground"
        pt={{ base: "20px", md: "60px" }}
        px={{ base: 4, md: 6, lg: 8 }}
      >
        <VStack
          align="center"
          maxWidth="800px"
          margin="0 auto"
          pt={{ base: 4, md: 8 }}
        >
          <Heading
            as="h1"
            size={{ base: "md", md: "lg" }}
            textAlign="center"
            color="heading"
            pb={{ base: 2, md: 4 }}
          >
            {isEditMode ? "Edit Campaign" : "Create a Campaign"}
          </Heading>

          <Box
            width="100%"
            bg="secondaryBackground"
            p={{ base: 4, sm: 6, md: 8, lg: 12 }}
            borderRadius="xl"
            boxShadow="md"
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[activeStep]}
              onSubmit={async (values, actions) => {
                if (activeStep === steps.length - 1) {
                  await handleSubmit(values, actions);
                } else {
                  setActiveStep(activeStep + 1);
                  actions.setTouched({});
                  actions.setSubmitting(false);
                }
              }}
              enableReinitialize
            >
              {(props) => (
                <Form>
                  <VStack spacing={{ base: 6, md: 8 }} width="100%">
                    <FormStepper steps={steps} activeStep={activeStep} />

                    <Box width="100%" pt={{ base: 4, md: 10 }}>
                      {renderStepContent(activeStep, props)}
                    </Box>

                    <HStack
                      justify="space-between"
                      width="100%"
                      pt={{ base: 4, md: 6 }}
                      flexDir={{ base: "column", sm: "row" }}
                      spacing={{ base: 4, sm: 0 }}
                    >
                      <Button
                        variant="ghost"
                        onClick={() =>
                          setActiveStep(Math.max(activeStep - 1, 0))
                        }
                        isDisabled={activeStep === 0}
                        width={{ base: "100%", sm: "auto" }}
                      >
                        Previous
                      </Button>
                      <Button
                        variant="primary"
                        type="submit"
                        isLoading={props.isSubmitting}
                        width={{ base: "100%", sm: "auto" }}
                      >
                        {activeStep === steps.length - 1
                          ? isEditMode
                            ? "Update Campaign"
                            : "Create Campaign"
                          : "Next"}
                      </Button>
                    </HStack>
                  </VStack>
                </Form>
              )}
            </Formik>
          </Box>
        </VStack>
      </Box>
    </>
  );
};

export default CampaignForm;
