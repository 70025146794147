import React from "react";
import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../contexts/AuthContext";

const LogoutButton = () => {
  const navigate = useNavigate();
  const { logout } = UserAuth();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (error) {
      console.error("Failed to log out", error);
    }
  };

  return (
    <Button onClick={handleLogout} variant="link">
      Logout
    </Button>
  );
};

export default LogoutButton;
