import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

export const AuthorizationService = {
  async canAccessCampaign(userId, campaignId) {
    try {
      console.log("Checking campaign access for:", { userId, campaignId });

      const userDoc = await getDoc(doc(db, "users", userId));
      if (!userDoc.exists()) {
        console.log("User document not found");
        return false;
      }

      const userData = userDoc.data();
      console.log("User data:", userData);
      console.log("User campaigns:", userData.campaigns);

      if (userData.campaigns?.includes(campaignId)) {
        console.log("User has campaign in their campaigns array");
        return true;
      }

      // Check if user is an agent in this campaign
      const agentDoc = await getDoc(
        doc(db, `campaigns/${campaignId}/agents`, userId)
      );
      if (agentDoc.exists()) {
        console.log("User is an agent in this campaign");
        return true;
      }

      console.log("User does not have access to this campaign");
      return false;
    } catch (error) {
      console.error("Error checking campaign access:", error);
      return false;
    }
  },

  async canAccessOrganization(userId, organizationId) {
    try {
      console.log("Checking organization access for:", {
        userId,
        organizationId,
      });

      const userDoc = await getDoc(doc(db, "users", userId));
      if (!userDoc.exists()) {
        console.log("User document not found");
        return false;
      }

      const userData = userDoc.data();
      // Check if organizationId exists in the user's organizations array
      return userData.organizations?.includes(organizationId) || false;
    } catch (error) {
      console.error("Error checking organization access:", error);
      return false;
    }
  },
};
