import React from "react";
import { Input } from "@chakra-ui/react";

const PhoneNumberInput = ({ field, ...props }) => {
  const formatPhoneNumber = (value) => {
    // Remove all non-numeric characters
    const phoneNumber = value.replace(/\D/g, "");

    // Format the number as (XXX) XXX-XXXX
    if (phoneNumber.length <= 3) {
      return phoneNumber;
    } else if (phoneNumber.length <= 6) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    } else {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`;
    }
  };

  const handleChange = (e) => {
    const formattedNumber = formatPhoneNumber(e.target.value);
    // Update the form field with just the digits for validation
    field.onChange({
      target: {
        name: field.name,
        value: formattedNumber.replace(/\D/g, ""),
      },
    });
  };

  return (
    <Input
      {...props}
      {...field}
      onChange={handleChange}
      value={formatPhoneNumber(field.value)}
      placeholder="(555) 555-5555"
      maxLength={14}
    />
  );
};

export default PhoneNumberInput;
