import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  FormErrorMessage,
  Select,
  useToast,
  Heading,
  Stack,
  HStack,
  Text,
  Image,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { db } from "../firebase";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Navbar from "../components/Navbar";
import { apiUrl } from "../config";
import FormStepper from "../components/FormStepper";
import PhoneNumberInput from "../components/PhoneNumberInput";
import { UserAuth } from "../contexts/AuthContext";
import { businessCategories } from "../constants/businessCategories";
import BusinessFormContent, { steps } from "../components/BusinessFormContent";

const BusinessForm = () => {
  const { businessId, campaignId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [activeStep, setActiveStep] = useState(0);
  const [logo, setLogo] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);
  const [initialValues, setInitialValues] = useState({
    name: { en: "", fr: "" },
    description: { en: "", fr: "" },
    websiteUrl: "",
    googleProfileUrl: "",
    category: { en: "", fr: "" },
    email: "",
    phoneNumber: "",
    couponDetails: { en: "", fr: "" },
    termsAndExclusions: { en: "", fr: "" },
    passcode: "",
    confirmPasscode: "",
  });

  const { user } = UserAuth();

  const isEditMode = !!businessId;
  const [businessCampaignId, setBusinessCampaignId] = useState(campaignId);

  useEffect(() => {
    const fetchBusiness = async () => {
      if (isEditMode) {
        try {
          const businessDoc = await getDoc(doc(db, "businesses", businessId));
          if (businessDoc.exists()) {
            const businessData = businessDoc.data();
            setInitialValues({
              ...businessData,
              confirmPasscode: businessData.passcode,
            });
            setBusinessCampaignId(businessData.campaignId);
            if (businessData.logoUrl) {
              setLogoPreview(businessData.logoUrl);
            }
          } else {
            toast({
              title: "Error",
              description: "Business not found",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            navigate(-1);
          }
        } catch (error) {
          console.error("Error fetching business:", error);
          toast({
            title: "Error",
            description: "Failed to fetch business data",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    };

    fetchBusiness();
  }, [businessId, isEditMode, navigate, toast]);

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLogo(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (values, actions) => {
    try {
      const formData = new FormData();

      // Add all the nested objects as stringified JSON
      formData.append("name", JSON.stringify(values.name));
      formData.append("description", JSON.stringify(values.description));
      formData.append("category", JSON.stringify(values.category));
      formData.append("couponDetails", JSON.stringify(values.couponDetails));
      formData.append(
        "termsAndExclusions",
        JSON.stringify(values.termsAndExclusions)
      );

      // Add regular fields
      formData.append("websiteUrl", values.websiteUrl || "");
      formData.append("googleProfileUrl", values.googleProfileUrl || "");
      formData.append("email", values.email);
      formData.append("phoneNumber", values.phoneNumber);
      formData.append("passcode", values.passcode);

      // Add required IDs
      formData.append("agentId", user.uid);
      formData.append("campaignId", businessCampaignId);

      // Add logo if exists
      if (logo) {
        formData.append("logo", logo);
      }

      const idToken = sessionStorage.getItem("idToken");

      if (isEditMode) {
        await axios.put(`${apiUrl}/businesses/${businessId}`, formData, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "multipart/form-data",
          },
        });
      } else {
        await axios.post(`${apiUrl}/addBusiness`, formData, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "multipart/form-data",
          },
        });
      }

      toast({
        title: "Success",
        description: `Business ${
          isEditMode ? "updated" : "created"
        } successfully`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Navigate back to the campaign's business list
      navigate(`/campaign/${campaignId}`);
    } catch (error) {
      console.error("Error submitting business:", error);
      toast({
        title: "Error",
        description: `Failed to ${isEditMode ? "update" : "create"} business`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    actions.setSubmitting(false);
  };

  const handleCategoryChange = (selectedEnglishCategory, formikProps) => {
    // Find the matching category object from businessCategories
    const selectedCategory = businessCategories.find(
      (category) => category.en === selectedEnglishCategory
    );

    if (selectedCategory) {
      // Set both English and French values
      formikProps.setFieldValue("category", {
        en: selectedCategory.en,
        fr: selectedCategory.fr,
      });
    }
  };

  // Move validationSchemas inside the component
  const validationSchemas = [
    // Step 1: Business Info
    Yup.object().shape({
      name: Yup.object().shape({
        en: Yup.string().required("Business name (English) is required"),
        fr: Yup.string(),
      }),
      description: Yup.object().shape({
        en: Yup.string()
          .max(75, "Description must be at most 75 characters")
          .required("Business description (English) is required"),
        fr: Yup.string().max(75, "Description must be at most 75 characters"),
      }),
      websiteUrl: Yup.string().url("Invalid URL").nullable().default(""),
      googleProfileUrl: Yup.string().url("Invalid URL").nullable().default(""),
      category: Yup.object().shape({
        en: Yup.string().required("Category (English) is required"),
        fr: Yup.string().required("Category (French) is required"),
      }),
    }),
    // Step 2: Contact Info
    Yup.object().shape({
      email: Yup.string().email("Invalid email").required("Email is required"),
      phoneNumber: Yup.string()
        .matches(/^\d{10}$/, "Phone number must be 10 digits")
        .required("Phone number is required"),
    }),
    // Step 3: Coupon Details
    Yup.object().shape({
      couponDetails: Yup.object().shape({
        en: Yup.string().required("Coupon details (English) are required"),
        fr: Yup.string(),
      }),
      termsAndExclusions: Yup.object().shape({
        en: Yup.string(),
        fr: Yup.string(),
      }),
    }),
    // Step 4: Security
    Yup.object().shape({
      passcode: Yup.string()
        .min(4, "Passcode must be at least 4 characters")
        .required("Passcode is required")
        .test(
          "unique",
          "This passcode is already in use",
          async function (value) {
            if (!value) return true; // Skip validation if empty

            try {
              const q = query(
                collection(db, "businesses"),
                where("passcode", "==", value)
              );
              const snapshot = await getDocs(q);

              // For edit mode, exclude current business
              if (businessId) {
                return snapshot.empty || snapshot.docs[0].id === businessId;
              }

              return snapshot.empty;
            } catch (error) {
              console.error("Error checking passcode uniqueness:", error);
              return false; // Fail validation if there's an error
            }
          }
        ),
      confirmPasscode: Yup.string()
        .oneOf([Yup.ref("passcode"), null], "Passcodes must match")
        .required("Confirm passcode is required"),
    }),
  ];

  return (
    <>
      <Navbar />
      <Box minH="calc(100vh - 60px)" bg="primaryBackground" pt="60px" px={4}>
        <VStack align="center" maxWidth="800px" margin="0 auto" pt={4}>
          <Heading size="lg">
            {isEditMode ? "Edit Business" : "Add a Business"}
          </Heading>
          <Text mb={4} color="text">
            {isEditMode
              ? "Edit the details of the business or it's coupon offering"
              : "Complete this form to add a business to your campaign's coupon book."}
          </Text>
          <Box
            mb={20}
            width="100%"
            bg="secondaryBackground"
            p={{ base: 4, sm: 6, md: 8, lg: 12 }}
            borderRadius="xl"
            boxShadow="md"
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[activeStep]}
              onSubmit={async (values, actions) => {
                if (activeStep === steps.length - 1) {
                  await handleSubmit(values, actions);
                } else {
                  setActiveStep(activeStep + 1);
                  actions.setTouched({});
                  actions.setSubmitting(false);
                }
              }}
            >
              {(props) => (
                <BusinessFormContent
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  handleLogoChange={handleLogoChange}
                  logoPreview={logoPreview}
                  setLogoPreview={setLogoPreview}
                  isEditMode={isEditMode}
                  isSubmitting={props.isSubmitting}
                />
              )}
            </Formik>
          </Box>
        </VStack>
      </Box>
    </>
  );
};
export default BusinessForm;
