export const businessCategories = [
  { en: "Restaurant", fr: "Restaurant" },
  { en: "Retail", fr: "Commerce de détail" },
  { en: "Beauty & Wellness", fr: "Beauté et bien-être" },
  { en: "Fitness", fr: "Fitness" },
  { en: "Home Services", fr: "Services à domicile" },
  { en: "Professional Services", fr: "Services professionnels" },
  { en: "Automotive", fr: "Automobile" },
  { en: "Entertainment", fr: "Divertissement" },
  { en: "Education", fr: "Éducation" },
  { en: "Technology", fr: "Technologie" },
  { en: "Other", fr: "Autre" },
];
