import React, { useState } from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  Image,
  Input,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Divider,
  useToast,
  Link,
  InputGroup,
  InputRightElement,
  IconButton,
  Flex,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Checkbox,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { UserAuth } from "../contexts/AuthContext";
import GoogleSignInButton from "../components/GoogleSignInButton";
import logo from "../assets/logos/logo.png";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import axios from "axios";
import { apiUrl } from "../config";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const toast = useToast();
  const { signUpWithEmail, signInWithGoogle } = UserAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [pendingRegistration, setPendingRegistration] = useState(null);
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};
    if (!email) newErrors.email = "Email is required";
    if (!password) newErrors.password = "Password is required";
    if (password.length < 6)
      newErrors.password = "Password must be at least 6 characters";
    if (!firstName) newErrors.firstName = "First name is required";
    if (!lastName) newErrors.lastName = "Last name is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRegistrationAttempt = (registrationType, data = null) => {
    setPendingRegistration({ type: registrationType, data });
    setIsModalOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    handleRegistrationAttempt("email", {
      email,
      password,
      firstName,
      lastName,
    });
  };

  const processRegistration = async () => {
    if (!agreedToTerms || !pendingRegistration) return;

    setIsLoading(true);
    try {
      let userData = {};

      if (pendingRegistration.type === "email") {
        const { email, password, firstName, lastName } =
          pendingRegistration.data;
        userData = await signUpWithEmail(email, password, firstName, lastName);
      } else if (pendingRegistration.type === "google") {
        userData = await signInWithGoogle();
      }

      console.log("Before delay - userData:", userData);
      await new Promise((resolve) => setTimeout(resolve, 5000));
      console.log("After delay - userData:", userData);

      if (userData?.email && userData?.uid) {
        try {
          const idToken = sessionStorage.getItem("idToken");
          await axios.post(
            `${apiUrl}/processPendingInvites`,
            {
              email: userData.email,
              userId: userData.uid,
            },
            {
              headers: {
                Authorization: `Bearer ${idToken}`,
              },
            }
          );

          console.log("Processed pending invites");
        } catch (error) {
          console.error("Error processing pending invites:", error);
          toast({
            title: "Note",
            description:
              "Account created, but there was an issue processing invitations. Please contact support if needed.",
            status: "warning",
            duration: 7000,
            isClosable: true,
          });
        }
      }

      setIsModalOpen(false);

      if (pendingRegistration.type === "email") {
        navigate("/verify-email");
      } else {
        navigate("/home");
      }
    } catch (error) {
      toast({
        title: "Registration failed",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
      setPendingRegistration(null);
      setAgreedToTerms(false);
    }
  };

  return (
    <Box
      width="100%"
      minHeight="100vh"
      bg="primaryBackground"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      px={4}
      py={8}
    >
      <Stack align={"center"} textAlign={"center"} mb={8}>
        <Box as="button" cursor="pointer">
          <Image src={logo} alt="Logo" width={["200px", "250px"]} mb={8} />
        </Box>
        <Heading fontSize={"xl"} textAlign={"center"} color="heading">
          Create an account
        </Heading>
        <Text textAlign="center" color="text" fontSize="sm">
          Or{" "}
          <Link
            as={RouterLink}
            to="/"
            color="text"
            textDecoration={"underline"}
            // fontWeight="bold"
            _hover={{ textDecoration: "none" }}
          >
            sign in to your account
          </Link>
        </Text>
      </Stack>
      <Box
        maxWidth={["100%", "450px", "500px"]}
        width="100%"
        bg="secondaryBackground"
        p={[6, 8, 10]}
        borderRadius="xl"
        boxShadow="md"
      >
        <VStack align="stretch">
          <GoogleSignInButton
            text="Sign up with Google"
            onRegisterAttempt={() => handleRegistrationAttempt("google")}
          />

          <Flex align="center" pb={8}>
            <Divider borderColor="gray.300" />
            <Text px={2} color="gray.500" fontWeight="medium">
              or
            </Text>
            <Divider borderColor="gray.300" />
          </Flex>

          <form onSubmit={handleSubmit}>
            <VStack spacing={4}>
              <Box
                display="flex"
                flexDir={["column", "row"]}
                gap={4}
                width="100%"
              >
                <FormControl isInvalid={errors.firstName}>
                  <FormLabel>First Name</FormLabel>
                  <Input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <FormErrorMessage>{errors.firstName}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.lastName}>
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <FormErrorMessage>{errors.lastName}</FormErrorMessage>
                </FormControl>
              </Box>

              <FormControl isInvalid={errors.email}>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.password}>
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <Input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputRightElement>
                    <IconButton
                      icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                      variant="ghost"
                      size="sm"
                      onClick={() => setShowPassword(!showPassword)}
                      aria-label={
                        showPassword ? "Hide password" : "Show password"
                      }
                    />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{errors.password}</FormErrorMessage>
              </FormControl>

              <Button
                mt={2}
                width="100%"
                variant={"secondary"}
                type="submit"
                isLoading={isLoading}
              >
                Register
              </Button>
            </VStack>
          </form>
        </VStack>
      </Box>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Terms of Service</ModalHeader>
          <ModalBody>
            <Text mb={4}>
              Please read and agree to our Terms of Service and Privacy Policy.
            </Text>
            <Checkbox
              isChecked={agreedToTerms}
              onChange={(e) => setAgreedToTerms(e.target.checked)}
            >
              I agree to the Terms of Service and Privacy Policy
            </Checkbox>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="secondary"
              onClick={processRegistration}
              isDisabled={!agreedToTerms}
              isLoading={isLoading}
            >
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Register;
