import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  VStack,
  Heading,
  Text,
  useToast,
  Container,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { db } from "../firebase";
import {
  collection,
  doc,
  writeBatch,
  getDoc,
  updateDoc,
  arrayUnion,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import AgentInput from "../components/AgentInput";
import Navbar from "../components/Navbar";

const validationSchema = Yup.object().shape({
  agents: Yup.array()
    .of(
      Yup.object().shape({
        email: Yup.string()
          .email("Invalid email")
          .required("Email is required"),
        isAdmin: Yup.boolean(),
      })
    )
    .min(1, "Add at least one Team Member")
    .max(10, "You can add up to 10 Team Members at a time"),
});

const AddAgents = () => {
  const { campaignId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  const handleSubmit = async (values, { resetForm }) => {
    setIsSubmitting(true);
    const batch = writeBatch(db);
    const agentsRef = collection(doc(db, "campaigns", campaignId), "agents");

    try {
      for (const agent of values.agents) {
        // Check if user exists
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("email", "==", agent.email));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          throw new Error(`User with email ${agent.email} does not exist`);
        }

        const userDoc = querySnapshot.docs[0];
        const userId = userDoc.id;
        const userData = userDoc.data();

        // Check if the user is already an agent for this campaign
        const existingAgentRef = doc(agentsRef, userId);
        const existingAgentDoc = await getDoc(existingAgentRef);

        if (existingAgentDoc.exists()) {
          throw new Error(
            `User with email ${agent.email} is already an agent for this campaign`
          );
        }

        // Add campaign to user's campaigns array
        const userRef = doc(db, "users", userId);
        await updateDoc(userRef, {
          campaigns: arrayUnion(campaignId),
        });

        // Add agent to campaign's agents subcollection
        batch.set(existingAgentRef, {
          email: agent.email,
          name: `${userData.firstName} ${userData.lastName}`,
          isAdmin: agent.isAdmin || false,
        });
      }

      await batch.commit();
      toast({
        title: "Team members added successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      resetForm();
    } catch (error) {
      console.error("Error adding team member: ", error);
      toast({
        title: "Error adding team member",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const buttonSize = useBreakpointValue({ base: "sm", md: "md" });
  const containerWidth = useBreakpointValue({
    base: "100%",
    md: "80%",
    lg: "60%",
  });

  return (
    <>
      <Navbar />
      <Box minH="calc(100vh - 60px)" bg="primaryBackground" pt="60px" px={4}>
        <VStack align="center" maxWidth="500px" margin="0 auto" pt={8}>
          <Heading as="h1" size="lg" textAlign="center" color="heading" pb={1}>
            Add Team Members to Campaign
          </Heading>
          <Text textAlign="center" color="text" pb={4}>
            Add team members to your campaign by entering their email addresses
            below.
          </Text>
          <Box
            width="100%"
            bg="secondaryBackground"
            p={10}
            borderRadius="xl"
            boxShadow="md"
          >
            <Formik
              initialValues={{ agents: [{ email: "", isAdmin: false }] }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values }) => (
                <Form>
                  <VStack spacing={4}>
                    <FieldArray name="agents">
                      {({ push, remove }) => (
                        <>
                          {values.agents.map((_, index) => (
                            <AgentInput
                              key={index}
                              index={index}
                              remove={remove}
                              isLast={index === values.agents.length - 1}
                              isRequired={true}
                            />
                          ))}
                          {values.agents.length < 10 && (
                            <Button
                              onClick={() =>
                                push({ email: "", isAdmin: false })
                              }
                              variant={"primary"}
                              size={buttonSize}
                              width="100%"
                              mt={4}
                            >
                              Add Another Team Member
                            </Button>
                          )}
                        </>
                      )}
                    </FieldArray>
                    <Text fontSize="sm" color="gray.600">
                      You can add up to 10 Team Members at a time.
                    </Text>
                    <Button
                      type="submit"
                      variant={"secondary"}
                      isLoading={isSubmitting}
                      loadingText="Adding Agents"
                      size={buttonSize}
                      width="100%"
                    >
                      Add Team Members to Campaign
                    </Button>
                  </VStack>
                </Form>
              )}
            </Formik>
          </Box>
        </VStack>
      </Box>
    </>
  );
};

export default AddAgents;
