import React from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  Circle,
  Icon,
  Button,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { TimeIcon } from "@chakra-ui/icons";
import Navbar from "../components/Navbar";

const PendingApproval = () => {
  const navigate = useNavigate();

  return (
    <>
      <Navbar external={false} />
      <Box minH="calc(100vh - 60px)" bg="primaryBackground" pt="60px">
        <VStack
          spacing={6}
          py={12}
          px={4}
          align="center"
          maxW="600px"
          mx="auto"
          animation="fadeIn 0.5s ease-in"
        >
          <Circle
            size="100px"
            bg="primaryHighlight"
            color="white"
            animation="scaleIn 0.3s ease-in-out"
          >
            <Icon as={TimeIcon} w={10} h={10} />
          </Circle>

          <Box textAlign="center" pt={4}>
            <Heading size="lg" mb={4} color="heading">
              Organization Pending Approval
            </Heading>
            <Text fontSize="lg" color="gray.600" mb={8}>
              Thank you for creating an account. Your account is currently
              pending approval. We'll notify you once your account has been
              approved and you can access the full features of the application.
            </Text>
            <Button
              leftIcon={<FaHome />}
              variant="secondary"
              size="md"
              onClick={() => navigate("/home")}
            >
              Return Home
            </Button>
          </Box>
        </VStack>
      </Box>
    </>
  );
};

export default PendingApproval;
