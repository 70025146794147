import React from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  Circle,
  Icon,
  Button,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";

const SubmissionSuccess = () => {
  const navigate = useNavigate();

  return (
    <VStack
      spacing={6}
      py={12}
      px={4}
      align="center"
      maxW="600px"
      mx="auto"
      animation="fadeIn 0.5s ease-in"
    >
      <Circle
        size="100px"
        bg="primaryHighlight"
        color="white"
        animation="scaleIn 0.3s ease-in-out"
      >
        <Icon as={CheckIcon} w={10} h={10} />
      </Circle>

      <Box textAlign="center" pt={4}>
        <Heading size="lg" mb={4} color="heading">
          Submission Successful!
        </Heading>
        <Text fontSize="lg" color="gray.600" mb={8}>
          Your organization profile has been submitted for review. We'll notify
          you once it's been approved.
        </Text>
        <Button
          leftIcon={<FaHome />}
          variant="secondary"
          size="md"
          onClick={() => navigate("/home")}
        >
          Return Home
        </Button>
      </Box>
    </VStack>
  );
};

export default SubmissionSuccess;
