"use client";

import React from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  Circle,
  Icon,
  Button,
} from "@chakra-ui/react";
import { CheckIcon, WarningIcon, CloseIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import Navbar from "./Navbar";

export default function Result({ result, type }) {
  const navigate = useNavigate();
  let headline = "";
  let body = "";
  let icon = null;

  if (result === "success" && type === "payment") {
    headline = "Purchase Successful";
    body =
      "Thank you for supporting this campaign! You will receive an email with your coupon book shortly.";
    icon = CheckIcon;
  } else if (result === "cancellation" && type === "payment") {
    headline = "Purchase Cancelled";
    body =
      "Your purchase has been cancelled, and no charges have been made to your method of payment. If you require assistance, please contact us.";
    icon = WarningIcon;
  } else if (result === "failure" && type === "payment") {
    headline = "Purchase Failed";
    body =
      "There has been an issue with your purchase, and no charges have been made to your method of payment. If you require assistance, please contact us.";
    icon = CloseIcon;
  } else if (result === "failure" && type === "other") {
    headline = "Something went wrong";
    body =
      "We ran into an issue. Please try again, and if the issue persists, reach out for assistance.";
    icon = CloseIcon;
  }

  return (
    <>
      <Navbar external={true} />
      <Box minH="calc(100vh - 60px)" bg="primaryBackground" pt="60px">
        <VStack
          spacing={6}
          py={12}
          px={4}
          align="center"
          maxW="600px"
          mx="auto"
          animation="fadeIn 0.5s ease-in"
        >
          <Circle
            size="100px"
            bg={
              result === "success"
                ? "primaryHighlight"
                : result === "cancellation"
                ? "orange.400"
                : "red.500"
            }
            color="white"
            animation="scaleIn 0.3s ease-in-out"
          >
            <Icon as={icon} w={10} h={10} />
          </Circle>

          <Box textAlign="center" pt={4}>
            <Heading size="lg" mb={4} color="heading">
              {headline}
            </Heading>
            <Text fontSize="lg" color="gray.600" mb={8}>
              {body}
            </Text>
            <Button
              leftIcon={<FaHome />}
              variant="secondary"
              size="md"
              onClick={() => navigate("/support")}
            >
              Return Home
            </Button>
          </Box>
        </VStack>
      </Box>
    </>
  );
}
