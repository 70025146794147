import { border, extendTheme } from "@chakra-ui/react";

const customTheme = extendTheme({
  fonts: {
    body: "Montserrat, sans-serif",
    heading: "Montserrat, sans-serif",
  },
  colors: {
    primaryBackground: "#f7fafc",
    secondaryBackground: "#ffffff",
    primaryHighlight: "#CDFF7A",
    secondaryHighlight: "#ee6c4d",
    text: "#000000",
    heading: "#000000",
    white: "#FFFFFF",
    // lightGray: "#5A5A5A",
    lightGray: "#c6c6c6",
    warning: "#E53E3E",
    darkGray: "#6A6A7E",
  },

  components: {
    Button: {
      variants: {
        primary: {
          bg: "primaryHighlight",
          color: "black",

          _hover: {
            bg: "#8bde00",
          },
          _disabled: {
            bg: "primaryHighlight",
            cursor: "not-allowed",
            _hover: {
              bg: "primaryHighlight !important",
            },
          },
        },
        secondary: {
          bg: "black",
          color: "white",
          _hover: {
            bg: "#8f8f8f",
            color: "white",
          },
          _disabled: {
            bg: "black",
            cursor: "not-allowed",
            _hover: {
              bg: "#8f8f8f !important",
            },
          },
        },
        outline: {
          borderColor: "black",
          color: "black",
          borderWidth: "1px",
          _hover: {
            borderColor: "black",

            color: "white",
            bg: "black",
          },
        },
        secondaryOutline: {
          borderColor: "secondaryHighlight",
          color: "secondaryHighlight",
          borderWidth: "1px",
          _hover: {
            borderColor: "secondaryHighlight",
            color: "secondaryHighlight",
            bg: "#d2f0fe",
          },
        },
        outlineNoBorder: {
          borderColor: "none",
          color: "lightGray",
          _hover: {
            borderColor: "none",
            color: "white",
            bg: "none",
          },
        },

        nav: {
          color: "white",
          _hover: {
            color: "#c1c8d2",
          },
        },
        navLanding: {
          color: "black",
          _hover: {
            color: "gray.400",
          },
        },
        link: {
          color: "text",
          _hover: {
            color: "text",
            textDecoration: "underline",
          },
        },
        linkSecondary: {
          color: "white",
          textDecoration: "underline",
          _hover: {
            color: "white",
            textDecoration: "none",
          },
        },
        stealth: {
          bg: "transparent",
          color: "black",

          _hover: {
            bg: "gray.100",
            color: "black",
          },
        },
        google: {
          // border: "1px solid",
          // borderColor: "lightGray",
          bg: "#4285f4",
          color: "white",
          _hover: {
            // border: "1px solid",
            // borderColor: "lightGray",
            bg: "#a9c7fa",
            color: "white",
          },
        },
      },
    },

    Input: {
      baseStyle: {
        field: {
          bg: "transparent",
          color: "text !important",
        },
      },
      variants: {
        outline: {
          field: {
            borderColor: "lightGray",
            _hover: {
              borderColor: "lightGray",
            },
            _focus: {
              borderColor: "black !important",
              boxShadow: "0 0 0 1px black !important",
              outline: "none !important",
            },
          },
        },
      },
      defaultProps: {
        variant: "outline",
        focusBorderColor: "black",
      },
    },

    Textarea: {
      baseStyle: {
        field: {
          bg: "transparent",
          color: "text !important",
        },
      },
      variants: {
        outline: {
          field: {
            borderColor: "lightGray",
            _hover: {
              borderColor: "lightGray",
            },
            _focus: {
              borderColor: "black !important",
              boxShadow: "0 0 0 1px black !important",
              outline: "none !important",
            },
          },
        },
      },
      defaultProps: {
        variant: "outline",
        focusBorderColor: "black",
      },
    },

    Progress: {
      baseStyle: {
        filledTrack: {
          bg: "primaryHighlight",
        },
      },
    },

    Stepper: {
      baseStyle: {
        indicator: {
          borderColor: "lightGray",
        },
        separator: {
          borderColor: "lightGray",
        },
        stepIconContainer: {
          bg: "white",
          borderColor: "lightGray",
          borderWidth: "2px",
          "&[data-status=complete]": {
            bgColor: "red",
            borderColor: "red",
          },
          "&[data-status=active]": {
            bgColor: "red",
            borderColor: "red",
          },
        },
        label: {
          color: "darkGray",
          fontWeight: "medium",
          _active: {
            color: "text",
            fontWeight: "bold",
          },
          _complete: {
            color: "text",
            fontWeight: "bold",
          },
        },
        number: {
          fontSize: "sm",
          color: "darkGray",
          _active: {
            color: "black",
          },
          _complete: {
            color: "black",
          },
        },
        icon: {
          color: "black",
        },
      },
      sizes: {
        sm: {
          stepIconContainer: {
            width: "6",
            height: "6",
          },
        },
        md: {
          stepIconContainer: {
            width: "8",
            height: "8",
          },
        },
        lg: {
          stepIconContainer: {
            width: "10",
            height: "10",
          },
        },
      },
      defaultProps: {
        size: "md",
        colorScheme: "primary",
      },
    },

    Step: {
      baseStyle: {
        _active: {
          "& .chakra-step__indicator": {
            borderColor: "primaryHighlight",
            bg: "primaryHighlight",
          },
          "& .chakra-step__title": {
            color: "text",
            fontWeight: "bold",
          },
          "& .chakra-step__number": {
            color: "black",
          },
        },
        _complete: {
          "& .chakra-step__indicator": {
            borderColor: "primaryHighlight",
            bg: "primaryHighlight",
          },
          "& .chakra-step__separator": {
            borderColor: "primaryHighlight",
          },
          "& .chakra-step__title": {
            color: "text",
            fontWeight: "bold",
          },
        },
        _invalid: {
          "& .chakra-step__indicator": {
            borderColor: "warning",
            bg: "white",
          },
          "& .chakra-step__separator": {
            borderColor: "warning",
          },
          "& .chakra-step__title": {
            color: "warning",
          },
        },
      },
    },
  },
});

export default customTheme;
